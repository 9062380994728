import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import TopNavImage from "./topNavImage";
// import Logo from '../images/img.png'

const Header = ({ siteTitle }) => {
    const [isExpanded, toggleExpansion] = useState(false);

    return (
        <header className="bg-blue-600">
            <div className="flex flex-wrap items-center justify-between max-w-4.5xl mx-auto p-4 md:p-8">
                <Link className="flex items-center no-underline text-white" to="/">
                  <div style={{height: '55px', width: '55px'}}><TopNavImage /></div>
                    &nbsp;
                    <span className="font-bold text-xl tracking-tight">
            {siteTitle}
          </span>
                </Link>

                <button
                    className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-white"
                    onClick={() => toggleExpansion(!isExpanded)}
                >
                    <svg
                        className="fill-current h-3 w-3"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>
                <nav
                    className={`${
                        isExpanded ? `block` : `hidden`
                    } md:block md:flex w-full md:w-auto`}
                >
                    <Link
                        className="font-bold block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white"
                        to="/about"
                    >
                        About
                    </Link>
                    <Link
                        className="font-bold block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white"
                        to="/news"
                    >
                        News
                    </Link>

                    <Link
                        className="font-bold block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white"
                        to="/labs"
                    >
                        Laboratories
                    </Link>
                    <Link
                        className="font-bold block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white"
                        to="/contact"
                    >
                        Contact
                    </Link>
                </nav>
            </div>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: `Atlee Biotech`,
}

export default Header
