import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link } from "gatsby"
import { faTwitter, faLinkedinIn, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {

    return (
        <section>
            <footer className="bg-blue-600">
                <nav className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm">
                    <div className='flex-initial'>
                        <p className="text-white">
                            <br />
                            {/*P: <a className='underline' href='tel:415-727-8445'>(415) 727-8445</a>*/}
                            <br />
                            1155 Camino Del Mar, #432, Del Mar, CA
                        </p>
                        <p>
                            <a
                                className="font-bold no-underline text-white"
                                href='https://www.linkedin.com/company/67162494/'
                                aria-label="LinkedIn"
                            >
                                {/*LinkedIn*/}
                                {/*<FontAwesomeIcon icon={faLinkedin} size="4x" style={{color:"#ff6347"}} />*/}
                                <FontAwesomeIcon
                                    className="p-2"
                                    icon={faLinkedinIn}
                                    size="3x"
                                    style={{color:"#ffffff"}}
                                />
                            </a>
                            &nbsp;&nbsp;
                            {/*<br />*/}
                            <a
                                className="font-bold no-underline text-white"
                                href={'#'}
                                aria-label="Instagram"
                            >
                                {/*Instagram*/}
                                <FontAwesomeIcon
                                    className="p-2"
                                    icon={faInstagram}
                                    size="3x"
                                    style={{color:"#ffffff"}}
                                />
                            </a>
                            &nbsp;&nbsp;
                            {/*<FontAwesomeIcon icon={faInstagramSquare} size="4x" style={{color:"#ff6347"}} />*/}
                            {/*<br />*/}
                            <a
                                className="font-bold no-underline text-white"
                                href='https://twitter.com/gitclinic'
                                aria-label="Twitter"
                            >
                                {/*Twitter*/}
                                <FontAwesomeIcon
                                    className="p-2"
                                    icon={faTwitter}
                                    size="3x"
                                    style={{color:"#ffffff"}}
                                />

                            </a>
                            &nbsp;&nbsp;
                            <a
                                className="font-bold no-underline text-white"
                                href="https://www.facebook.com/GitClinic-249605639412682"
                                aria-label="Facebook"
                            >
                                <FontAwesomeIcon
                                    className="p-2"
                                    icon={faFacebookF}
                                    size="3x"
                                    style={{color:"#ffffff"}}
                                />
                            </a>
                        </p>
                    </div>
                    <div className='flex-initial px-4'>
                        <span className='text-xl font-bold text-gray-300'>Support</span>
                        <br />
                        <Link className='text-white mt-2 mb-2 py-3' to='/contact'>Contact</Link>
                        <br />
                        <Link className='text-white mt-2 mb-2 py-3' to='/about'>About</Link>
                        <br />
                        <Link className='text-white mt-2 py-3' to='/news'>News</Link>
                        <br />
                        <Link className='text-white mt-2 py-3' to='/labs'>Labs</Link>
                    </div>
                    <div className='flex-initial'>
                        <span className='text-xl font-bold text-gray-300'>Company</span>
                        <br />
                        <Link
                            className="no-underline text-white mt-2 mb-2 py-3"
                            to='/terms-of-service/'
                        >
                            Terms of Service
                        </Link>
                        <br />
                        <Link
                        className="no-underline text-white mt-2 mb-2 py-3"
                        to={'/privacy-policy/'}
                    >
                        Privacy Policy
                    </Link>
                        {/*<br />*/}
                        {/*<Link*/}
                        {/*    className="no-underline text-white mt-2 mb-2 py-3"*/}
                        {/*    to='/refund-policy/'*/}
                        {/*>*/}
                        {/*    Refund Policy*/}
                        {/*</Link>*/}
                        <br />
                        <Link
                            className="no-underline text-white mt-2 mb-2 py-3"
                            to='/disclaimer/'
                        >
                            Disclaimer
                        </Link>
                        <br />
                        <Link
                        className="no-underline text-white mt-2 mb-2 py-3"
                        to='/contact/'
                    >
                        Media Inquiries
                    </Link>
                    </div>
                </nav>
                <div className="flex justify-between max-w-4xl mx-auto p-4 md:p-2 text-sm
                text-white">
                    <span>© Atlee Biotech, Inc. {new Date().getFullYear()} All Rights Reserved</span>
                </div>

            </footer>
        </section>
    )
}

export default Footer