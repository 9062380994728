/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import Header from "./header";
import Footer from "./footer";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitter, faLinkedin, faLinkedinIn,
    faInstagram, faInstagramSquare, faFacebook, faFacebookF } from '@fortawesome/free-brands-svg-icons'

library.add(fab, faTwitter, faLinkedin, faLinkedinIn,
    faInstagram, faInstagramSquare, faFacebook, faFacebookF);

const Layout = ({ children }) => {
    // const data = useStaticQuery(graphql`
    //     query SiteTitleQuery {
    //         site {
    //             siteMetadata {
    //                 title
    //             }
    //         }
    //     }
    // `)

    return (
        <>
            {/*<Provider theme={defaultTheme}>*/}
            <div className="flex flex-col font-sans min-h-screen text-gray-900">
                <Header />
                <main className="flex flex-col flex-1
                mx-auto py-8 w-full">
                    {children}
                </main>
                <Footer />
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
